import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import {GitHub, Email, Event} from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as Logo } from './wcb_logo_dark_background.svg';
import { ReactComponent as InlineLogo } from './wcb_inline_logo_dark_background.svg';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      Wright Cloud Builds
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const cards = [
  {
    url: "https://www.sports-gram.com/",
    title: "sportsgram",
    description: "Sports data visualization for the everday fanatic"
  },
  {
    url: "https://www.melacoffey.com/",
    title: "melacoffey.com",
    description: "A personal website for a Boston University (BU) PhD candidate to display her research and maintain a blog"
  },
  {
    url: "https://www.dejon.net/",
    title: "dejon.net",
    description: "Founder's personal website"
  },
];

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function Album() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <a href="https://github.com/wrightcloudbuilds">
            <GitHub sx={{ mr: 2}} />
          </a>
          <a href="mailto:wrightcloudbuilds@gmail.com">
            <Email sx={{ mr: 2}} />
          </a>
          {/* <a href="https://calendly.com/wrightcloudbuilds/intro-call" style={{color:"white", textDecoration:"none", flexGrow: 1}}>
            Schedule a meeting
          </a> */}
          <InlineLogo height={20} />
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Logo style={{ paddingBottom: 50 }}/>
            <Typography
              component="h4"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Wright Cloud Builds
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Custom-built cloud solutions that typically have a focus on being event and data driven, while incorporating serverless technology. All solutions are tailored towards the needs of the application. 
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="xl">
          <Grid container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            rowSpacing={5}
            >
            {cards.map((card, index) => (
              <Grid item 
                key={index} 
                // xs={12}
                sm={12}
                md={5.5}
                // lg={3.75}
              >
                <Card
                  sx={{ height: 450, display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="iframe"
                    src={card.url}
                    sx={{ height: 300, display: 'flex', flexDirection: 'column' }}
                  />
                  <CardContent sx={{ height: 100, display: 'flex', flexDirection: 'column' }}>
                    <Typography gutterBottom variant="h6" component="h4">
                      {card.title}
                    </Typography>
                    <Typography>
                      {card.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ height: 50, display: 'flex', flexDirection: 'column' }}>
                    <Button href={card.url} target="_blank" size="small">View</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}